
import axios from 'axios'
import { base64StringToBlob } from 'blob-util';

function setter(collect, temp) {
  var set = {}
  if (collect == 'universes') {
    set = {
      univ_name: temp.univ_name,
      univ_exclu: temp.univ_exclu,
      univ_usrExclu: temp.univ_usrExclu,
      univ_thumbnail: temp.univ_thumbnail,
      univ_template: temp.univ_template,
      univ_price: temp.univ_price
    }
  } else if (collect == 'users') {
    set = {
      usr_firstname: temp.usr_firstname,
      usr_lastname: temp.usr_lastname,
      usr_email: temp.usr_email,
      usr_ent: temp.usr_ent,
      usr_role: temp.usr_role
    }


  }
  else if (collect == 'auth') {
    set = {
      usr_firstname: temp.usr_firstname,
      usr_lastname: temp.usr_lastname,
      usr_email: temp.usr_email,
      usr_ent: temp.usr_ent,
      usr_role: temp.usr_role
    }
  
  } else if (collect == 'videos') {
    set = {
      vdo_name: temp.vdo_name,
      usr_id: temp.usr_id,
      vdo_draft: temp.vdo_draft
    }
  } else if (collect == 'identities') {
    set = {
      _id : temp._id,
      ident_name: temp.ident_name,
      ident_baseline: temp.ident_baseline,
      usr_id: temp.usr_id,
      ident_logo: temp.ident_logo,
      ident_colors : temp.ident_colors,
      ident_font : temp.ident_font
    }
  }

  return temp
}

function binaryToBlob(temp) {
  let document = {}
  for (let field in temp)  //reformating data in to array with info of collect and media data
  {
    if (field == "media" && temp[field].length > 0) {
      document.media_data = temp[field][0]
      let thumbs = temp[field][0]
      try {
        let blob = base64StringToBlob(thumbs.media_data, thumbs.media_mimetype);
        let img = URL.createObjectURL(blob)
        temp[field] = img
      } catch {

      }

    }
    
    document[field] = temp[field]
  }
  return document
}
var collect_api = {
  get(url, params) {
    if(params == undefined){
      return axios.get(url)
      .then((res) => {
        return Promise.resolve(res.data)

      }).catch((err) => {
        return Promise.reject(err)
      })
    }else{
      return axios.get(url, { params: params })
      .then((res) => {
      
       let reformatData
        if (res.data.length == undefined) {
          
           reformatData = binaryToBlob(res.data)
        } else {
          reformatData = []
          for (let elem of res.data) {
            let doc = binaryToBlob(elem)
            reformatData.push(doc)
          }
        }
        
        return new Promise((resolve) => resolve(reformatData))

      })
    }
    
  }/*,
  getWMedia(url, params) { // To get information of one collection bind with his thumbnail
    /*return axios.get(url)
      .then((res) => {

        let collect = {}
        let media = {}
        
        for (let field in res.data)  //reformating data in to array with info of collect and media data
        {
          if(field != "media"){
            collect[field] = res.data[field]
          }else{
            media= res.data[field][0]
          } 
         
        }

      
      
        // Convert binary data in src img
        let blob = base64StringToBlob(media.media_data, media.media_mimetype);
        let img = URL.createObjectURL(blob)
       
        // Send json with data reformatted containing collect / media
        return new Promise((resolve) => resolve({collect : collect, media : img}))

      }).catch((err) => {
        return new Promise((resolve, reject) => reject(err))
      })
      return axios.get(url, { params: params })
      .then((res) => {

       let reformatData
        if (res.data.length == undefined) {
          
           reformatData = binaryToBlob(res.data)
        } else {
          reformatData = []
          for (let elem of res.data) {
            let doc = binaryToBlob(elem)
            reformatData.push(doc)
          }

        }
    
        return new Promise((resolve) => resolve(reformatData))

      })

  },

  getAllWMedia(url, params) { // To get information of one collection bind with his thumbnail
    return axios.get(url, { params: params })
    .then((res) => {
      let reformatData
     
      if (res.data.length == undefined) {
        
        reformatData = binaryToBlob(res.data)
      } else {
         reformatData = []
        for (let elem of res.data) {
          let doc = binaryToBlob(elem)
          reformatData.push(doc)

          
        }
      }
 
      return new Promise((resolve) => resolve(reformatData))

    })
  }*/,
  update(collect, temp) {
    let set = setter(collect, temp)
    
    return axios.put('/' + collect + '/' + temp._id, set)//this.img = res.data
      .then((res) => {
        return new Promise((resolve) => resolve(res))
      })
      .catch((err) => {
        return new Promise((resolve, reject) => reject(err))
      })
  },
  add(collect, temp) {
    let set = setter(collect, temp)
    return axios.post('/' + collect + '/', set)//this.img = res.data
      .then((res) => {
        return new Promise((resolve) => resolve(res.data))
      })
      .catch((err) => {
        return new Promise((resolve, reject) => reject(err))
      })
  },
  delete(collect, temp) {
    return axios.delete('/' + collect + '/' + temp._id)
      .then((res) => {
        return new Promise((resolve) => resolve(res))
      })
      .catch((err) => {
        return new Promise((resolve, reject) => reject(err))
      })
  }




}

export default collect_api
<template>
  <div style="margin-left: 2%">
    <div class="inline">
      <section>
        <div>
          <h2>Liste des musiques :</h2>
          <p
            @click="
              modification = true;
              temp = {};
              file = null;
            "
            style="margin-bottom: 5%"
          >
            Ajouter une nouvelle musique
          </p>
          <div style="overflow: auto; height: 28vh">
            <p @click="selectMusic(music)" v-for="music in musics" :key="music">
              {{ music.music_name }}
            </p>
          </div>
        </div>
      </section>

      <section v-if="modification" id="partModification">
        <h2 v-if="modification && temp._id == undefined">Ajout musique</h2>
        <h2 v-else>Modification musique</h2>
        <div class="inline">
          <label>Titre</label>
          <input type="text" v-model="temp.music_name" class="audio" />
        </div>
        <div @click="$refs.musicUploader.click()" id="music_area">
          <input
            type="file"
            hidden
            ref="musicUploader"
            max="1"
            @change="change_music()"
          />

          <p v-if="srcAudio == null && !loadingMusic" class="center audio">
            Cliquez pour upload votre musique
          </p>
          <p v-if="loadingMusic" class="center audio">
            Chargement audio en cours...
          </p>
          <div class="inline" v-if="srcAudio != null && !loadingMusic">
            <audio :src="srcAudio" controls class="audio"></audio>
            <img
              @click="deleteAudio"
              src="../../../../../assets/reset.png"
              alt=""
              style="
                width: 25px;
                height: 25px;
                margin-top: auto;
                margin-bottom: auto;
              "
            />
          </div>
        </div>

        <button class="button" @click="addMusic" v-if="temp._id == undefined">
          Ajouter
        </button>
        <div
          class="inline"
          v-if="temp._id != undefined"
          style="width: 58%; float: right"
        >
          <img
            @click="deleteMusic(temp._id)"
            src="../../../../../assets/trash.png"
            alt=""
            style="
              width: 25px;
              height: 25px;
              margin-top: auto;
              margin-bottom: auto;
            "
          />
          <button class="button" @click="updateMusic" style="margin-left: 5%">
            Modifier
          </button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "musicModifier",
  data() {
    return {
      musics: [],
      modification: false,
      temp: {},
      file: null,
      loadingMusic: false,
    };
  },
  props: ["mood_id"],
  components: {},
  computed: {
    srcAudio() {
      if (this.file != null) {
        let blob = window.URL;
        return blob.createObjectURL(this.file);
      } else if (this.temp.audio != undefined) {
        return URL.createObjectURL(this.temp.audio);
      } else {
        return null;
      }
    },
  },
  mounted() {
    axios.get("/musics", { params: { mood_id: this.mood_id } }).then((res) => {
      console.log(res.data)
      this.musics = res.data;
    });
  },

  watch: {
    mood_id(value) {
      axios.get("/musics", { params: { mood_id: value } }).then((res) => {
        this.musics = res.data;
      });
    },
  },

  methods: {
    addMusic() {
      this.Toaster.info("Ajout en cours...");
      var formData = new FormData();
      formData.append("music_name", this.temp.music_name);
      formData.append("mood_id", this.mood_id);

      if (this.file != null) {
        formData.append("file", this.file);
      }
      axios
        .post("/musics", formData)
        .then((res) => {
          this.temp = {};
          this.musics.push(res.data);
          this.modification = false;
          this.Toaster.success(this.$t('Toaster.success.add'));
        })
        .catch((err) => console.log(err));
    },
    updateMusic() {
      var formData = new FormData();
      formData.append("music_name", this.temp.music_name);
      formData.append("mood_id", this.mood_id);
      if (this.file != null) {
        formData.append("file", this.file);
      }
      axios.put("/musics/" + this.temp._id, formData).then(() => {
        this.temp = {};
        this.modification = false;
      });
    },
    change_music() {
      this.file = this.$refs["musicUploader"].files[0];
      if (this.file && this.file.name){
        this.temp.music_name = this.file.name
      }
    },
    selectMusic(music) {
      this.loadingMusic = true;
      this.file = null;
      this.temp = music;
      this.modification = true;
      axios
        .get("/musics/" + music._id + "/file", { responseType: "blob" })
        .then((res) => {
          this.loadingMusic = false;
          this.temp.audio = res.data;
        });
    },
    deleteAudio() {
      this.file = null;
      this.temp.audio = undefined;
    },
    deleteMusic(id) {
      axios
        .delete("/musics/" + id)
        .then((res) => {
          var index = this.musics.findIndex((m) => m._id == id);

          this.musics.splice(index, 1);
          this.temp = {};
          this.file = null;
          this.modification = false;
          this.Toaster.success(this.$t('Toaster.success.delete'));
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style src="@/views/Admin/subViews/style.css" scoped></style>
<style scoped>
#partModification {
  margin-left: 5%;
  width: 30%;
}
#collect {
  display: block;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color_mood);
  padding: 10px;
  padding-bottom: 5px;
  border-radius: 25px;

  font-size: 1.1vw;
}
#block_img {
  display: block;
  width: 18%;
  height: 100%;
  margin-left: 2%;
}
#block_field {
  display: block;
  width: 80%;
  margin-left: 2%;
}
label {
  line-height: 25px;
}
button {
  float: right;
  margin-top: 5%;
  margin-top: auto;
  margin-bottom: auto;
}

h2 {
  margin-top: 5%;
  margin-bottom: 5%;
}
.audio {
  float: right;
  width: 100%;
}
</style>


<template>
    <div>
      <input
        type="text"
        list="users"
        ref="usr_input"
        @change="changeValueSelected($event)"
      />
      <datalist id="users">
        <option v-for="user in users" :key="user" :value="user._id">
          {{ user.usr_firstname }} {{ user.usr_lastname }} - {{ user.usr_email }}
        </option>
      </datalist>

      <p v-for="user in usersSelected" :key="user">{{ user.usr_firstname }} {{ user.usr_lastname }} - {{ user.usr_email }} <a class="delete" @click="remove(user._id)">X</a></p>
    </div>
</template>

<script>
import collect_api from "../../../../../api/collect";
export default {
    props : ["ids_selected"],
    data () {
        return {
            users : [],
            usersSelected : []
        }
    },
    emits : ["list_changed"],
    
    mounted(){
        collect_api.get("/users").then((res) => {
            this.users = res;
            this.usersSelected = this.ids_selected.flatMap(id => this.users.find((u) => u._id == id) )
        });
    },

    methods : {
        changeValueSelected(evt){
            let user = this.users.find((u) => u.usr_email == evt.target.value);

            if (user != undefined) {

                this.usersSelected.push(user)
                
                //WRAP USR_SELECTED_LIST
                var new_usr_list =  this.usersSelected.map(usr => usr._id)
                this.$emit("list_changed", new_usr_list)

                this.$refs.usr_input.value = ''
            }
        },

        remove(id){
            this.usersSelected = this.usersSelected.filter(usr => usr._id != id)
            var new_usr_list =  this.usersSelected.map(usr => usr._id)
            this.$emit("list_changed", new_usr_list)
        }


    }
}
</script>

<style scopped>
a.delete{
    display : inline-block;
    font-weight: 600;
    cursor: pointer;
    color : white;
    padding-left: 3px;
    padding-right: 3px;
    background-color: var(--color2);
}
</style>
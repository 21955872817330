<template>
  <div>
    <img
      v-if="image != null && image != undefined && image != ''"
      :id="_uid"
      class="center img"
      ref="imageElement"
      @click="clicking"
      :src="image"
      :key="image"
      :style="{ 'cursor : pointer': editable }"
    />
    <img
      v-else
      class="center placeholder"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
      @click="clicking"
      :style="{ 'cursor : pointer': editable }"
    />
    <input type="file" ref="file" hidden @change="new_image($event)" />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import file_api from "../../api/file";

//FILES
import logo_placeholder from "../../assets/logo_placeholder.jpg";
import watermark_placeholder from "../../assets/watermark_placeholder.jpg";
import mood_visual_placeholder from "../../assets/mood_visual_placeholder.jpeg"

//REGEX
const url_regex =
  /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/;
const regex_id = /^[a-fA-F0-9]{24}$/;

export default {
  name: "EditableImage",
  props: {
    src_img: null,
    src_default: null,
    editable: {
      type: Boolean,
      default: false,
    },
    image_type: "", //If logo, display logo place holder
  },
  emits: ["change_image", "change"],

  data() {
    return {
      image: "",
      _uid : uuidv4()
    };
  },
  mounted() {
    this.load_image();
  },
  watch: {
    src_img(val, oldVal) {
      if (val != oldVal) {
        URL.revokeObjectURL(this.oldVal);
      }
      this.load_image();
    },
  },
  methods: {
    clicking() {
      if (this.editable) {
        this.$refs.file.click();
      }
    },
    load_image() {
      if (
        this.src_img != undefined &&
        this.src_img != null &&
        this.src_img != ""
      ) {
        //IF INPUT SRC IS AN _id => Fetch it from api (with auth)!
        if (regex_id.test(this.src_img)) {
          file_api
            .get(this.src_img)
            .then((image) => {
              this.image = image;
            })
            .catch((err) => {
              console.error("[IMAGE COMPO]", err);
            });
        } else if (url_regex.test(this.src_img)) {
          //IF INPUT SRC IS AN URL
          this.image = this.src_img;
        }
      } else {
          switch (this.src_default){
            case "default_logo":
              this.image = logo_placeholder;
              break;
            
            case "default_watermark":
              this.image = watermark_placeholder;
              break;

            case "default_mood":
              this.image = mood_visual_placeholder;
              break;

            default: break;
          }
        }
    },
    new_image(event) {
      let file = event.target.files[0];

      if (!["image/png", "image/svg+xml", "image/jpeg"].includes(file.type)){
        this.Toaster.error(this.$t('editableText.badFormat'))
        return;
      }

      if (file.size/1024/1024 > 3){
        this.Toaster.error(this.$t('editableText.tooHeavy'))
        return;
      }

      this.image = URL.createObjectURL(file);
      this.$emit("change_image", file);
      this.$emit("change", { target: { url: this.image, file: file } }); //Emulate classique input event
    },
  },
};
</script>
    
<style scoped>
.img {
  position: relative;
  border-radius: var(--border_radius);
  max-width: 100%;
  max-height: 100%;
  width: auto;
  cursor: pointer;
  object-fit: contain;
}

.placeholder {
  height: 100%;
  width: 100%;
}
</style>

<template>
  <p class="autosave">
    <span v-if="data.status == 0" class="saving"></span>
    <span v-if="data.status == 1 || data.status == 3" class="saved"></span>
    <span v-if="data.status == 2" class="error"></span>
    <a v-if="data.status == 0">{{ $t('AutoSave.saving') }}</a>
    <a v-if="data.status == 1 && data.lastSave == ''">{{ $t('AutoSave.upToDate') }}</a>
    <!-- For first displaying -->
    <a v-if="data.status == 1 && data.lastSave != ''">{{ $t('AutoSave.updated') }} {{ appendLeadingZeroes(data.lastSave)
    }}</a>
    <a v-if="data.status == 2">{{ $t('AutoSave.error') }}</a>
    <a v-if="data.status == 3">Insertion </a>
  </p>
</template>

<script>
//LIBS

export default {
  data() {
    return {
      actions: [],
      intervaler: null
    }
  },
  name: "autosaveinfo",
  props: {
    saveDelay: {
      type: Number,
      default: 2
    },
    lastSave: {
      required: false,
    },
    data: {
      default: {},
    },
    enableTrigger : {
      default : true
    }
  },
  // Status 0 = saving;
  // Status 1 = Saved;
  // Status 2 = Error;
  emits: ["save", "newSaveData"],
  created() {
    window.addEventListener("beforeunload", (event) => {
      if (this.data.status != 1) {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = "";
      } else {
        event.returnValue
      }
    });
  },
  mounted() {
    if (this.data.status == undefined) {
      console.warn("[AUTO SAVE] Status undefined");
      this.data.status = 1;
    }
    if (this.data.lastSave == undefined) {
      this.data.lastSave = "";
    }
    if (this.data.lastModification == undefined) {
      this.data.lastModification = "";
    }


  },

  watch: {
    lastModification() {

      if (this.intervaler == null) {
        if (this.enableTrigger == true){
          this.init_intervaler()
        }
      }
    },
    status(val){
      if(val == 1){
        this.data.lastSave = new Date()
      }
    }
  },
  computed: {
    lastModification() { return this.data.lastModification },
    status() { return this.data.status }
  },
  methods: {
    init_intervaler() {
      this.intervaler = setInterval(() => {

        var currentTime = new Date();
        var delta = (currentTime - this.data.lastModification) / 1000;
     
        if (this.saveDelay == Math.round(delta)) {
         
          //SAVE
          if (this.data.status == 0) {
            this.$emit("save");
          }
          this.actions = []
         /* this.data.lastSave = (() => {
            return new Date();
          })();*/

          this.data.status = 1;
          this.$emit("newSaveData", this.data);
        }


      }, 1000);
    },
    appendLeadingZeroes(date) {
      //date = new Date(date)
      let h;
      let m;

      if (typeof date == "object") {
        h = Number(date.getHours());
        m = Number(date.getMinutes());
      } else {
        h = Number(date.match(/\d{1,2}/g)[0]);
        m = Number(date.match(/\d{1,2}/g)[1]);
      }

      if (h <= 9) {
        h = "0" + h;
      }

      if (m <= 9) {
        m = "0" + m;
      }

      return h + ":" + m;
    },
  },
  beforeUnmount() {
    try {
      clearInterval(this.intervaler)
    } catch {
      console.debug('[AUTO SAVE] No intervaler to clear')
    }
  }
};
</script>

<style scoped>
.autosave {
  position: absolute;
  font-size: 0.9em;
  font-weight: 300;
  top: -80px;
  right: 20%;
  /* before 0 */
  text-align: right;
}

.autosave>span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}

span.saving {
  background-color: rgb(240, 146, 58);
  animation: 0.3s saving alternate infinite;
}

@keyframes saving{
  0%   {opacity: 1;}
  100% { opacity:0.2 ;}
}

span.saved {
  background-color: rgb(107, 201, 89);
}

span.error {
  background-color: var(--color_negative);
}
</style>
import axios from 'axios'
import FormData from 'form-data'
var file_api = {

  get(src) {
    return axios.get('/medias/' + src, { responseType: 'blob' })//this.img = res.data
      .then((res) => {
        let img = URL.createObjectURL(res.data)

        return new Promise((resolve) => { resolve(img) })
      })
  },

  post(file, name, media_type, usr_id, anim_type, media_path) {
    let data = new FormData();
    data.append('file', file);
    if (media_type){
      data.append('media_type', media_type);
    }
    if (usr_id){
      data.append('usr_id', usr_id);
    }
    if (name != undefined && name != "") {
      data.append('media_name', name)
    }

    if (anim_type != undefined && anim_type !== "") {
      data.append('anim_type', anim_type)
    }

    if (media_path){
      data.append('media_path', media_path);
    }

    return axios.post('/medias/', data, { contentType: 'multipart/form-data' })
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return new Promise((resolve, reject) => reject(err))
      })
  },
  getMany(ids) {
    return axios.get('/medias/', {
      
      params: { ids }
    })
      .then((medias) => {
        var blobs = []
        medias.data.forEach(media => {
          blobs.push(new Promise((resolve) => { 
           resolve({_id : media._id, blob : URL.createObjectURL(new Blob([Buffer.from(media.media_data.data)], {type : media.media_mimetype}))})
          }))
        });
        return Promise.all(blobs)
        .then((res)=>{
          return Promise.resolve(res) 
        })/*
          let img = URL.createObjectURL(res.data)*/

     
      })
      .catch((err) => {
        console.debug(err)
      })
  }
}
export default file_api
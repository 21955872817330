function jsonToFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => {
        if (object[key] != null) {
            
            if (Array.isArray(object[key])) {
                for (var i = 0; i < object[key].length; i++) {
                    formData.append(key +'[]', object[key][i]);
                }
            }else{
                formData.append(key, object[key])
            }
        }
       

    });
    return formData;
}

export { jsonToFormData }
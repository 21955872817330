<template>
  <div>
    <h1>Collection Mood</h1>
    <Autosaveinfo v-if="slct_value != ''" :data="autoSave" @save="update()" />
    <section id="collect">
      <section class="inline" id="select">
        <div>
          <select name="" v-model="slct_value" @change="onChangeSelect($event)" placeholder="Musique">
            <option value="" selected disabled hidden>Choisir un mood</option>
            <option v-for="mood in moods" :key="mood._id" :value="mood._id">
              {{ mood.mood_name }}
            </option>
          </select>
        </div>
        <div v-if="slct_value == ''">OU</div>
        <div class="inline">
          <button v-if="slct_value == ''" @click="add">Ajouter</button>
          <button v-if="slct_value != ''" @click="reset_slct(), (slct_value = '')">
            Nouveau
          </button>
          <button v-if="slct_value != ''" @click="remove">Supprimer</button>
        </div>
      </section>

      <section class="inline">
        <section id="block_img">
          <div>
            <EditableImage :editable="true" @change_image="change_image" :src_default="'default_mood'"
              :src_img="temp.mood_thumbnail" :key="temp" />
          </div>
        </section>
        <section id="block_field">
          <!-- Mood name input -->
          <section class="inline">
            <div style="width: 42%">
              <div class="inline">
                <label :class="{ error: errors.mood_name.color }" for="">Nom :</label>
                <input v-model="temp.mood_name" type="text" class="save" />
              </div>
            </div>
          </section>

          <!-- Modd exlusif checkbox-->
          <section class="inline">
            <label for="mood_exclusif" style="width: 120px">Mood exclusif :</label>
            <input id="mood_exclusif" v-model="temp.mood_exclu" style="display: block" type="checkbox" class="save" />
          </section>

          <usrExclu_selector v-if="temp.mood_exclu" :ids_selected="temp.mood_usrExclu || []" @list_changed="
            (list) => {
              temp.mood_usrExclu = list;
              triggerAutoSave();
            }
          " />
        </section>
      </section>
      <MusicModifier v-if="temp._id != undefined" :mood_id="temp._id" />
    </section>
  </div>
</template>
<script>
//NPM
import $ from "jquery";

//API
import collect_api from "../../../../api/collect";
import file_api from "../../../../api/file";
import axios from "axios";
//Components
import Autosaveinfo from "../../../../components/Object/AutoSaveInfo";
import EditableImage from "../../../../components/Object/EditableImage";
import MusicModifier from "./components/musicModifier";
import usrExclu_selector from "./components/usrExclu_selector.vue";
//LIB
import verifGlobal from "../../../../lib/verifGlobal";
import { jsonToFormData } from "../../../../lib/jsonToFormData";

export default {
  name: "Admin.mood",
  data() {
    return {
      moods: {},
      users: [],
      temp: {
        mood_name: "",
        mood_exclu: false,
      },
      data_iptList: false,
      slct_value: "",
      usr_select: [],

      errors: {
        mood_name: {},

        global: false,
      },
      autoSave: {
        status: 1,
        lastModification: Date,
        lastSave: "",
      },
    };
  },
  components: {
    EditableImage,
    Autosaveinfo,
    MusicModifier,
    usrExclu_selector,
  },
  //emits: ["change_image"],

  mounted() {
    collect_api.get("/moods").then((moods) => {
      this.moods = moods;
      console.log(this.moods);
    });

    collect_api.get("/users").then((res) => {
      this.users = res;
      this.data_iptList = true;
    });

    $(" .save, input[type=number], input[type=checkbox], textarea ").on(
      "keyup",
      () => {
        this.triggerAutoSave();
      }
    );

    $("input[type=number], input[type=checkbox], datalist").on("click", () => {
      this.triggerAutoSave();
    });

    this.autoSave.status = 3;
    //AutoSave Interval
    setInterval(() => {
      if (this.slct_value != "") {
        var currentTime = new Date();
        var delta = (currentTime - this.autoSave.lastModification) / 1000;
        const saveDelay = 2;
        if (saveDelay == Math.round(delta)) {
          //SAVE
          console.debug("Saving data...");
          this.update();
          this.autoSave.lastSave = (() => {
            var date = new Date();
            return date.getHours() + ":" + date.getMinutes();
          })();

          if (this.errors.global) {
            this.autoSave.status = 2;
          } else {
            this.autoSave.status = 1;
          }
        }
      }
    }, 1000);
  },

  watch: {
    // verification of user input after display of errors
    "temp.mood_name": function (value) {
      if (this.errors.global) {
        if (!verifGlobal.isEmpty(value)) {
          this.errors.mood_name.color = false;
        }
      }
    },

    /* image: function (src) {
      // verification: set of picture after display error
      if (this.errors.global) {
        if (!verifGlobal.isEmpty(src)) {
          this.errors.img.color = false;
        }
      }
    },*/
    slct_value: function (value) {
      if (value == "") {
        this.autoSave.status = 3;
      }
    },
  },

  methods: {
    triggerAutoSave() {
      this.autoSave.status = 0;
      this.autoSave.lastModification = new Date();

    },
    onChangeSelect(event) {
      this.reset_slct();
      if (event.target.value != "") {
        collect_api
          .get("/moods/" + event.target.value, { media: true })
          .then((mood) => {
            this.$nextTick(() => {
              this.temp = mood;

              this.autoSave.lastSave = new Date(this.temp.updated_at);
              this.autoSave.status = 1;
              if (this.temp.mood_usrExclu != undefined) {
                for (let id of this.temp.mood_usrExclu) {
                  this.usr_select.push(
                    this.users.filter((u) => u._id == id)[0]
                  );
                  this.users = this.users.filter((u) => u._id != id);
                }
              }
            });

            //var date =
          }); //this.moods.filter((b) => b._id == event.target.value)[0];
      } else {
        this.temp = {};
      }
    },
    update() {
      this.errors.global = false;
      for (let field in this.temp) {
        if (
          this.errors[field] != undefined &&
          verifGlobal.isEmpty(this.temp[field])
        ) {
          this.errors[field].color = true;
        }
      }

      for (let field in this.errors) {
        if (this.errors[field].color) {
          this.errors.global = true;
        }
      }

      if (!this.errors.global) {
        let formData = jsonToFormData(JSON.parse(JSON.stringify(this.temp)))
        formData.append("file", this.image)

        axios.put('/moods/' + this.temp._id, formData)
          .then((res) => {
            this.image = undefined
            this.Toaster.success(this.$t("Toaster.success.update"));
            this.temp = res.data
            let index = this.moods.findIndex(
              (obj) => obj._id == res.data._id
            );
            this.moods[index] = res.data;
          })
          .catch((err) => {
            console.log(err);
            this.Toaster.error(this.$t("Toaster.error.update"));
          })

      } else {
        this.Toaster.error(this.$t("Toaster.error.requiredFields"));
      }
    },
    add() {
      this.errors.global = false;

      for (let field in this.temp) {
        if (
          this.errors[field] != undefined &&
          verifGlobal.isEmpty(this.temp[field])
        ) {
          this.errors[field].color = true;
        }
      }

      for (let field in this.errors) {
        if (this.errors[field].color) {
          this.errors.global = true;
        }
      }

      if (!this.errors.global) {
        let formData = jsonToFormData(this.temp)
        if (this.image != undefined) {
          formData.append("file", this.image);
        }

        axios
          .post("/moods/", formData)
          .then((res) => {
            this.moods.push(res.data);
            this.reset_slct();
            this.slct_value = "";
            this.Toaster.success(this.$t("Toaster.success.add"));
          })
          .catch((err) => {
            this.Toaster.error(this.$t("Toaster.error.add"));
          });
      } else {
        this.Toaster.error(this.$t("Toaster.error.requiredFields"));
      }
    },
    remove() {
      collect_api
        .delete("moods", this.temp)
        .then(() => {
          this.moods = this.moods.filter((b) => b._id != this.temp._id);
          this.reset_slct();
          this.slct_value = "";
          this.Toaster.success(this.$t("Toaster.success.delete"));
        })
        .catch((err) => {
          this.Toaster.error(this.$t("Toaster.error.delete"));
        });
    },
    reset_slct() {
      for (let usr of this.usr_select) {
        this.users.push(usr);
      }

      this.usr_select = [];
      this.temp = {
        mood_name: "",
      };

      this.image = undefined;

      this.errors = {
        mood_name: {},

        // img: {},
        global: false,
      };
    },
    change_image(file) {
      this.temp.mood_path = file.name;
      this.image = file;
      if (this.temp._id != undefined) {
        this.update();
      }

    },
  },
};
</script>

<style src="@/views/Admin/subViews/style.css" scoped></style>
<style scoped>
#collect {
  display: block;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color_mood);
  padding: 10px;
  padding-bottom: 5px;
  border-radius: 25px;

  font-size: 1.1vw;
}

#block_img {
  display: block;
  width: 18%;
  height: 100%;
  margin-left: 2%;
}

#block_field {
  display: block;
  width: 80%;
  margin-left: 2%;
}

label {
  line-height: 25px;
}
</style>

